import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkills = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="skills" className="pt-10 pb-10">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-16">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-2 ">
            MY <span className="text-red">SKILLS</span>
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">
           1. Proficient in HTML, CSS, and JavaScript. <br />
           2. Strong experience with front-end frameworks such as React and Angular.  <br />
           3. Experience with responsive design and cross-browser compatibility  <br />
           4. Experience with CSS preprocessors such as SASS and LESS. <br />
           5. Experience with JavaScript libraries such as jQuery and React.  <br />
           6. Experience with UI/UX design principles.  <br />
           7. Strong problem-solving and debugging skills.  <br />
           8. Experience with CSS frameworks such as Bootstrap and Tailwind CSS.   <br />
           9. Experience with Database such as Oracle and MongoDB.  <br />
           
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10"
                src="assets/a1.jpg"
                
              />
            </div>
          ) : (
            <img alt="skills" className="z-10" src="assets/a1.jpg"  />
          )}
        </div>
      </div>

   
    </section>
  );
};

export default MySkills;
